<template>
  <div
    class="v-stepper-counter"
    :class="[
      large ? 'v-stepper-counter-lg' : '',
      vertical ? 'v-stepper-counter__vertical' : 'v-stepper-counter__horizontal',
      disabled ? 'disabled' : '',
      appConfig.VueSettingsPreRun.ElementStepperStyle === 'Westfold'
        ? 'v-stepper-counter-westfold'
        : '',
      appConfig.VueSettingsPreRun.ElementStepperStyle === 'WestEmnet'
        ? 'v-stepper-counter-west-emnet'
        : '',
      appConfig.VueSettingsPreRun.ElementStepperStyle === 'Westmarch'
        ? 'v-stepper-counter-westmarch'
        : '',
      className
    ]"
    :aria-label="label.toString()"
    :data-test-id="dataTestId"
    :inert="disabled"
  >
    <arora-button
      class-name="v-stepper-counter-button v-stepper-counter-plus"
      ignore-settings
      :disabled="disablePlusButton || lockPlus"
      data-test-id="stepper-counter-plus"
      label="+"
      @click="async () => await onIncrementLocal()"
    >
      <icon-old-general-plus />
    </arora-button>
    <div
      class="v-stepper-counter-number"
      :class="[
        vertical
          ? 'v-stepper-counter-number__vertical'
          : 'v-stepper-counter-number__horizontal'
      ]"
    >
      <slot>
        {{ value }}
      </slot>
    </div>
    <transition
      mode="out-in"
      name="slidedown"
    >
      <arora-button
        v-if="showMinusOnZero || value > minimumValue"
        class-name="v-stepper-counter-button v-stepper-counter-minus"
        ignore-settings
        :disabled="value === minimumValue || lockMinus"
        data-test-id="stepper-counter-minus"
        label="-"
        @click="async () => await onDecrementLocal()"
      >
        <icon-old-general-minus />
      </arora-button>
    </transition>
  </div>
</template>

<script setup lang="ts">
import type { VElement } from '@arora/common'

const {
  className = '',
  minimumValue = 0,
  onDecrement,
  onIncrement,
  showMinusOnZero = true
} = defineProps<
  VElement & {
    disablePlusButton?: boolean
    vertical?: boolean
    onIncrement: () => Promise<void>
    onDecrement: () => Promise<void>
    showMinusOnZero?: boolean
    value: number
    minimumValue?: number
    large?: boolean
  }
>()

const appConfig = useAppConfig()

const lockPlus = ref<boolean>(false)
const lockMinus = ref<boolean>(false)

async function onIncrementLocal(): Promise<void> {
  lockPlus.value = true
  await onIncrement()
  lockPlus.value = false
}

async function onDecrementLocal(): Promise<void> {
  lockMinus.value = true
  await onDecrement()
  lockMinus.value = false
}
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-stepper-counter {
  display: grid;
  gap: 0;
  border-radius: variables.$BorderRadiusInput;
  overflow: hidden;
  padding: 0;

  &.v-stepper-counter__horizontal {
    grid-template-columns: 4fr 6fr 4fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'minus number plus';
  }

  &.v-stepper-counter__vertical {
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 5fr 2fr;
    grid-auto-flow: row;
    grid-template-areas:
      'plus'
      'number'
      'minus';
  }

  .v-stepper-counter-plus {
    grid-area: plus;
    text-align: center;
    min-width: 28px;

    svg {
      min-width: 1rem;
      height: auto;
      path {
        stroke: variables.$BodyTextColor;
      }
    }
  }

  .v-stepper-counter-number {
    grid-area: number;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: default;

    font-size: 1rem;
    min-width: 32px;
    user-select: none;
  }

  .v-stepper-counter-minus {
    grid-area: minus;
    text-align: center;
    min-width: 28px;

    svg {
      min-width: 1rem;
      height: auto;
      path {
        stroke: variables.$BodyTextColor;
      }
    }
  }

  .v-stepper-counter-button {
    outline: none;
    box-shadow: none;
    cursor: pointer;
    border: none;
    padding: 9px;
    font-size: 1rem;
    text-decoration: none;
    @include mixins.trans;

    display: flex;
    align-items: center;
    justify-content: center;

    &:focus,
    &:active,
    &:visited {
      outline: none;
      box-shadow: none;
      border-radius: variables.$BorderRadiusInput;
    }

    &:hover {
      outline: none;
    }
  }
}

.v-stepper-counter-westfold {
  box-shadow: variables.$InputShadow;
  background: variables.$FormBackground;

  .v-stepper-counter-number {
    color: variables.$BodyTextColor;
  }

  .v-stepper-counter-number__horizontal {
    border-top: none;
    border-bottom: none;
    border-left: variables.$BorderWidth solid variables.$BorderColorLight;
    border-right: variables.$BorderWidth solid variables.$BorderColorLight;
  }

  .v-stepper-counter-number__vertical {
    border-top: variables.$BorderWidth solid variables.$BorderColorLight;
    border-bottom: variables.$BorderWidth solid variables.$BorderColorLight;
    border-left: none;
    border-right: none;
  }

  .v-stepper-counter-button {
    background: variables.$PrimaryBackgroundColor;
    color: variables.$PrimaryColor;
    svg {
      path {
        stroke: variables.$PrimaryColor;
      }
    }
  }

  .v-stepper-counter-plus {
    &:hover {
      color: variables.$SuccessForeColor;
      background: variables.$SuccessColor;
      svg {
        path {
          stroke: variables.$SuccessForeColor;
        }
      }
    }
  }

  .v-stepper-counter-minus {
    &:hover {
      color: variables.$ErrorForeColor;
      background: variables.$ErrorColor;
      svg {
        path {
          stroke: variables.$ErrorForeColor;
        }
      }
    }
  }
}

.v-stepper-counter-west-emnet {
  box-shadow: variables.$InputShadow;
  background: variables.$PrimaryBackgroundColor;

  .v-stepper-counter-number {
    color: variables.$PrimaryColor;
  }

  .v-stepper-counter-number__horizontal {
    border-top: none;
    border-bottom: none;
    border-left: variables.$BorderWidth solid variables.$BorderColorLight;
    border-right: variables.$BorderWidth solid variables.$BorderColorLight;
  }

  .v-stepper-counter-number__vertical {
    border-top: variables.$BorderWidth solid variables.$BorderColorLight;
    border-bottom: variables.$BorderWidth solid variables.$BorderColorLight;
    border-left: none;
    border-right: none;
  }

  .v-stepper-counter-button {
    background: variables.$PrimaryBackgroundColor;
    color: variables.$PrimaryColor;
  }

  .v-stepper-counter-plus {
    &:hover {
      color: variables.$PrimaryBackgroundColor;
      background: variables.$PrimaryColor;
      svg {
        path {
          stroke: variables.$PrimaryBackgroundColor;
        }
      }
    }
  }

  .v-stepper-counter-minus {
    &:hover {
      color: variables.$PrimaryBackgroundColor;
      background: variables.$PrimaryColor;
      svg {
        path {
          stroke: variables.$PrimaryBackgroundColor;
        }
      }
    }
  }
}

.v-stepper-counter-westmarch {
  background: variables.$FormBackground;

  .v-stepper-counter-number {
    color: variables.$BodyTextColorLight;
  }

  .v-stepper-counter-number__horizontal {
    border-top: none;
    border-bottom: none;
    border-left: variables.$BorderWidth solid rgba(0, 0, 0, 0.05);
    border-right: variables.$BorderWidth solid rgba(0, 0, 0, 0.05);
  }

  .v-stepper-counter-number__vertical {
    border-top: variables.$BorderWidth solid rgba(0, 0, 0, 0.05);
    border-bottom: variables.$BorderWidth solid rgba(0, 0, 0, 0.05);
    border-left: none;
    border-right: none;
  }

  .v-stepper-counter-button {
    background: variables.$FormBackground;
    color: variables.$BodyTextColor;
  }

  .v-stepper-counter-plus {
    &:hover {
      color: variables.$SuccessForeColor;
      background: variables.$SuccessColor;
      svg {
        path {
          stroke: variables.$SuccessForeColor;
        }
      }
    }
  }

  .v-stepper-counter-minus {
    &:hover {
      color: variables.$ErrorForeColor;
      background: variables.$ErrorColor;
      svg {
        path {
          stroke: variables.$ErrorForeColor;
        }
      }
    }
  }
}

.v-stepper-counter-lg {
  .v-stepper-counter-button {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .v-stepper-counter-number {
    font-size: 1.3rem;
    font-weight: 600;
    padding: 16px 32px;
  }

  &.v-stepper-counter__horizontal {
    grid-template-columns: 2fr 7fr 2fr;
  }
}
</style>
